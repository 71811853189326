import axios from "axios";

export default function (module){
  const MODULE = module;
  return {
    createPaymentOrder({rootState}, payload) {
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${MODULE}paymentOrder/create?userId=${userId}`, payload)
    },
    getPaymentOrderById(_, id){
      return axios.get(`${MODULE}paymentOrder/getById?id=${id}`)
    },
    getPaymentOrderList(_, {
      divisionId, owner, currencyLetter, code
    }) {
      return axios.get(`${MODULE}paymentOrder/getByDivisionId?divisionId=${divisionId}&owner=${owner}&currencyLetter=${currencyLetter}&code=${code}`)
    },
    getPaymentOrderListFromDt({state}, {code}) {
      const declarationId = state.selected.declaration_id;
      const id = state.selected.id;
      return axios.get(`${MODULE}paymentOrderDebiting/getForReturn?id=${id}&declarationId=${declarationId}&code=${code}`)
    },
    createPaymentOrderDebit(_, payload) {
      return axios.post(`${MODULE}paymentOrderDebiting/create`, payload)
    },
    getDebitPaymentsList({state}) {
      const declarationId = state.selected.id;
      return axios.get(`${MODULE}paymentOrderDebiting/getByDeclarationId?declarationId=${declarationId}`)
    },
    deleteDebitPayment(_, id) {
      return axios.post(`${MODULE}paymentOrderDebiting/delete?id=${id}`)
    },
    downloadPaymentsPdf(_, id) {
      return axios.get(`${MODULE}declaration/paymentsPdf?declarationId=${id}`, {
        responseType: "blob"
      })
    },
    getJournal(_, params) {
      let url = `${MODULE}paymentOrder/getJournal`
      Object.entries(params)
        .filter(([,value]) => value !== "" && value !== null)
        .forEach(([key, value], index) => {
          const specialChar = index === 0 ? "?" : "&"
          if(key === "search"){
            if(value.value){
              url += `${specialChar}${value.condition}=${value.value}`
            }
          }else {
            url += `${specialChar}${key}=${value}`
          }
        })
      return axios.get(url)
    },
    deletePaymentOrder(_, id) {
      return axios.get(`${MODULE}paymentOrder/deleteById?id=${id}`)
    }
  }
}
